import {
  Text as EvergreenText,
  Heading,
  ListItem as EvergreenListItem,
} from 'evergreen-ui';
import styled from 'styled-components';

export const Acronym = styled(Heading)`
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  color: #fff;
  line-height: 2rem;
`;

export const Filter = styled(Heading)`
  font-family: 'Share Tech Mono', monospace;
  color: #ffffffaa;
  font-style: italic;
  font-size: 6rem;
  line-height: 6rem;

  @media only screen and (max-width: 767px) {
    font-size: 4rem;
    line-height: 4rem;
  }

  @media only screen and (max-width: 550px) {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
`;

export const Name = styled(Heading)`
  font-family: 'Lora', serif;
  font-size: 4rem;
  line-height: 4rem;
  color: #fff;
`;

export const Text = styled(EvergreenText)`
  font-family: 'Open Sans', sans-serif;
  color: ${(props) => (props.color ? props.color : '#ffffff88')};
  line-height: 1.5rem;
  font-size: 0.8rem;
`;

export const ContactText = styled(Text)`
  &:hover {
    color: #fff;
    cursor: pointer;
  }
`;

export const ListItem = styled(EvergreenListItem)`
  margin-top: ${(props) => (props.isfirstitem === 'first' ? 0 : 'default')};
  color: ${(props) => (props.color ? props.color : '#ffffff')};
  &:hover {
    border-color: #fff;
  }
`;

export const ListItemText = styled(Text)`
  color: #fff;
  display: inline-block;
  &:after {
    display: block;
    content: '';
    border-bottom: solid 2px #fff;
    transform: ${(props) => (props.active ? 'scaleX(1)' : 'scaleX(0)')};
    transition: transform 200ms ease-in;
    transform-origin: 0% 50%;
  }
  &:hover {
    border-color: #fff;
    cursor: pointer;
  }
  &:hover:after {
    transform: scaleX(1);
  }
`;

export const ListingWrapper = styled.div`
  border-top: 1px solid #cccccc22;
  background-color: ${(props) => (props.selected ? '#0000003d' : 'default')};
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: ${(props) =>
      props.selected ? '#0000003d' : '#00000011'};
  }
`;

export const ListingTitle = styled(Text)`
  padding: 0.5rem 2rem 0.5rem 2rem;
  font-size: 1rem;

  color: ${(props) => (props.selected ? '#fff' : 'default')};
  display: block;
  ${ListingWrapper}:hover & {
    color: #fff;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const Tech = styled(Text)`
  color: #ffffffcc;
  font-family: 'Share Tech Mono', monospace;

  margin-left: ${(props) => props.margingLeft || 'default'};

  padding: 0.25rem;
  transition: all 0.2s ease-in-out;

  background-color: ${(props) =>
    props.selected ? '#0000003d' : props.backgroundColor};
  box-shadow: ${(props) =>
    props.selected ? '2px 2px 4px #00000033' : props.boxShadow};

  &:hover {
    background-color: #0000003d;
    cursor: pointer;
    box-shadow: 2px 2px 4px #00000033;
  }
`;

export const Role = styled(Text)`
  color: #fff;
  padding-right: 1rem;
  text-transform: uppercase;
`;

export const Time = styled(Text)`
  color: #ffffffcc;
  text-transform: uppercase;
  font-size: 0.6rem;
  font-family: 'Share Tech Mono', monospace;
  line-height: 0.6rem;
  padding-left: 1px;
`;
