import { Pane, UnorderedList, CaretRightIcon } from 'evergreen-ui';
import { useContext } from 'react';
import styled from 'styled-components';
import { Text, Name, ListItem, ListItemText } from '../components/Typography';
import { techs } from '../utils/Data';
import { SortingContext } from '../App';
import ContactGraph from '../components/ContactGraph';
import { useMediaQuery } from 'react-responsive';

const CustomIcon = styled(CaretRightIcon)`
  margin-top: 2px;
`;

const MiddlePane = () => {
  const { filter, tab, setFilter, setTab, setSelection } = useContext(
    SortingContext
  );

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px' });
  const isMobile = useMediaQuery({ query: '(max-width: 767px' });

  return (
    <Pane
      paddingTop={isTabletOrMobile ? '1rem' : '2rem'}
      paddingBottom={isTabletOrMobile ? '1rem' : '2rem'}
      paddingRight='2rem'
      paddingLeft='2rem'
      height='100%'
      display='flex'
      flexDirection='column'
      justifyContent={isTabletOrMobile ? null : 'center'}
      position={isTabletOrMobile ? 'default' : 'sticky'}
      top='0'
    >
      <Pane
        display='flex'
        flexDirection={isTabletOrMobile ? 'row-reverse' : 'column'}
        alignItems={isTabletOrMobile ? 'center' : null}
      >
        {!isMobile && (
          <Pane
            height='100px'
            marginLeft='auto'
            marginRight='auto'
            marginTop='2rem'
          >
            <ContactGraph />
          </Pane>
        )}
        <Pane>
          <Text>Hi, my name is</Text>
          <Name>Yannick</Name>
          <Name>Robert</Name>
          <Text>
            I am a software developer and enjoy learning and experimenting with
            code. Creative problem-solving and the drive to continuously learn
            new things has led me to where I am today. My main focus these days
            is providing a positive digital experience through web applications.
          </Text>
        </Pane>
      </Pane>

      {isMobile && (
        <Pane
          height='100px'
          marginLeft='auto'
          marginRight='auto'
          marginTop='5rem'
          marginBottom='-3rem'
        >
          <ContactGraph />
        </Pane>
      )}

      <Pane marginTop='2rem'>
        <Text color='#fff'>
          Here are a few technologies I've been working with recently
        </Text>
        <UnorderedList
          style={{ columns: isMobile ? 2 : isTabletOrMobile ? 3 : 2 }}
          marginTop='1rem'
        >
          {techs.map((tech, i) => (
            <ListItem
              key={i}
              icon={CustomIcon}
              isfirstitem={i === 0 ? 'first' : 'no'}
            >
              <ListItemText
                active={tech === filter ? 1 : 0}
                onClick={() => {
                  setFilter(tech);
                  if (!tab) {
                    setTab('Projects');
                  }
                  setSelection();
                }}
              >
                {tech}
              </ListItemText>
            </ListItem>
          ))}
        </UnorderedList>
      </Pane>
    </Pane>
  );
};

export default MiddlePane;
