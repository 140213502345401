import { useContext } from 'react';
import { SortingContext } from '../App';
import { Text, Tech } from './Typography';
import { Pane } from 'evergreen-ui';

const TechStack = ({ stack }) => {
  const { filter, setFilter } = useContext(SortingContext);

  return (
    <Pane display='flex' paddingBottom='.5rem' flexWrap='wrap'>
      {stack.map((s, i) => (
        <Pane key={i}>
          <Tech
            selected={filter === s}
            onClick={() => setFilter(s)}
            marginLeft={i !== 0 ? '0.75rem' : undefined}
          >{`${s}`}</Tech>
          {i !== stack.length - 1 && <Text>, </Text>}
        </Pane>
      ))}
    </Pane>
  );
};

export default TechStack;
