import { CaretRightIcon, Pane } from 'evergreen-ui';
import { useContext } from 'react';
import { Text, ListingTitle, ListingWrapper, Role, Time } from './Typography';
import AnimateHeight from 'react-animate-height';
import { SortingContext } from '../App';
import _ from 'lodash';
import TechStack from './TechStack';
import styled from 'styled-components';
import { Scrollbar } from 'react-scrollbars-custom';

const Work = ({ isSelected, work, index }) => {
  const { setSelection, filter } = useContext(SortingContext);

  let numberOfVisibleProjects = work.filter((w) =>
    w?.stack?.some((s) => filter === s)
  ).length;

  return (
    <ListingWrapper onClick={() => setSelection(index)} selected={isSelected}>
      <ListingTitle selected={isSelected}>{_.head(work).company}</ListingTitle>
      <Pane maxHeight='50vh'>
        <AnimateHeight duration={500} height={isSelected ? 'auto' : '0%'}>
          <Scrollbar
            style={{
              height: `${numberOfVisibleProjects > 1 ? '425px' : '250px'}`,
            }}
          >
            {work.map(({ name, company, highlights, stack, start, end }, i) => {
              let startDate = new Date(start);
              let startText = `${startDate.toLocaleString('default', {
                month: 'long',
              })} ${startDate.getFullYear()}`;

              let endText = 'Present';
              if (end !== 'Present') {
                let endDate = new Date(end);
                endText = `${endDate.toLocaleString('default', {
                  month: 'long',
                })} ${endDate.getFullYear()}`;
              }

              return (
                <Pane key={i}>
                  {stack.some((s) => !filter || filter === s) && (
                    <Pane paddingLeft='2.5rem' paddingBottom='1rem' key={i}>
                      <Pane display='flex' alignItems='baseline'>
                        <Role>{name}</Role>
                        <Text>@ {company}</Text>
                      </Pane>
                      <Time>{`${startText} - ${endText}`}</Time>
                      <Pane
                        paddingTop='1rem'
                        paddingBottom='1rem'
                        display='flex'
                        flexDirection='column'
                      >
                        {highlights.map((highlight, i) => (
                          <Pane
                            key={i}
                            display='grid'
                            gridTemplateColumns='24px auto'
                          >
                            <CaretRightIcon color='#ffffff3d' marginTop='5px' />
                            <Text>{highlight}</Text>
                          </Pane>
                        ))}
                      </Pane>
                      <TechStack stack={stack} />
                      {numberOfVisibleProjects > 1 && i < stack.length - 1 && (
                        <HorizontalRule />
                      )}
                    </Pane>
                  )}
                </Pane>
              );
            })}
          </Scrollbar>
        </AnimateHeight>
      </Pane>
    </ListingWrapper>
  );
};

const HorizontalRule = styled.div`
  width: 33%;
  background-color: #ffffff3d;
  height: 1px;
  margin: 1rem auto;
`;

export default Work;
