import { Pane, CrossIcon } from 'evergreen-ui';
import { useContext } from 'react';
import styled from 'styled-components';

import { Tab } from '../components/Tabs';
import ListingContainer from '../components/ListingContainer';
import { Filter } from '../components/Typography';
import { SortingContext } from '../App';

import { useMediaQuery } from 'react-responsive';
import AnimateHeight from 'react-animate-height';

const tabs = ['Projects', 'Work', 'Resume'];

const CloseIcon = styled(CrossIcon)`
  color: #ffffff3d;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: #fff;
    cursor: pointer;
    transform: rotate(90deg);
  }
`;

const tabBgMobile =
  'linear-gradient(121deg, rgba(38, 11, 6, 1) 0%, rgba(38, 11, 6, .9) 100%)';

const RightPane = () => {
  const { filter, setFilter, tab, setTab, setSelection } = useContext(
    SortingContext
  );

  const isLaptop = useMediaQuery({ query: '(min-width: 1024px' });

  return (
    <Pane
      borderLeft='1px solid #cccccc22'
      display='flex'
      flexDirection={isLaptop ? 'column' : 'column-reverse'}
    >
      <Pane
        display='flex'
        justifyContent='space-around'
        height='50px'
        position={!isLaptop ? 'fixed' : null}
        width='100%'
        bottom={!isLaptop ? 0 : null}
        background={!isLaptop ? tabBgMobile : null}
        borderTop={!isLaptop ? '1px solid #ffffff3d' : 'none'}
        zIndex={999}
      >
        {tabs.map((t) => (
          <Pane display='flex' alignItems='center' key={t}>
            <Tab
              active={t === tab ? 1 : 0}
              onClick={() => {
                if (t === 'Resume') {
                  window.open('./yannick-robert-resume.pdf');
                } else {
                  setTab(t);
                  setSelection();
                }
              }}
            >
              {t}_
            </Tab>
          </Pane>
        ))}
      </Pane>
      <AnimateHeight
        animateOpacity
        duration={400}
        height={filter ? 'auto' : '0%'}
        style={{ order: !isLaptop ? 1 : null }}
      >
        <Pane
          display='flex'
          justifyContent='center'
          visibility={filter ? 'visible' : 'hidden'}
          marginTop={'2rem'}
        >
          <Filter>{`${filter}_`}</Filter>
          <CloseIcon
            onClick={() => {
              setFilter();
              setSelection();
            }}
          />
        </Pane>
      </AnimateHeight>
      <ListingContainer />
    </Pane>
  );
};

export default RightPane;
