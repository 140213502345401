import { Pane } from 'evergreen-ui';
import { useContext } from 'react';
import _ from 'lodash';
import FadeIn from 'react-fade-in';
import AnimateHeight from 'react-animate-height';

import { projects } from '../utils/Data';
import Project from './Project';
import { SortingContext } from '../App';
import Work from './Work';
import { useMediaQuery } from 'react-responsive';

function getFirstShownIndex(filter) {
  let shownProjects = _.filter(projects, (p) =>
    p.stack.some((s) => !filter || filter?.includes(s))
  );

  let firstShownProject = _.head(shownProjects);

  return _.findIndex(projects, (p) => p.name === firstShownProject.name);
}

const ListingContainer = () => {
  const { filter, tab, selection, setSelection } = useContext(SortingContext);

  let isBlankPane =
    filter === undefined && tab === undefined && selection === undefined;

  if (!isBlankPane && selection === undefined) {
    setTimeout(() => {
      setSelection(getFirstShownIndex(filter));
    }, 500);
  }

  const isLaptop = useMediaQuery({ query: '(min-width: 1024px' });

  return (
    <Pane
      display='flex'
      flexDirection='column'
      justifyContent={isLaptop ? 'flex-end' : 'flex-start'}
      marginTop='2rem'
      // height={isLaptop ? '80vh' : 'auto'}
      visibility={isBlankPane ? 'hidden' : 'visible'}
      overflow='hidden'
    >
      <AnimateHeight
        animateOpacity
        duration={100}
        height={isBlankPane ? '0%' : 'auto'}
      >
        {{
          Work: <WorkListings />,
          Projects: <ProjectListings />,
        }[tab] || <></>}
      </AnimateHeight>
      {!isLaptop && <Pane height={50}></Pane>}
    </Pane>
  );
};

const WorkListings = () => {
  const { filter, tab, selection } = useContext(SortingContext);

  const works = _.reduce(
    projects,
    (result, project) => {
      (result[project.company] || (result[project.company] = [])).push(project);
      return result;
    },
    {}
  );

  const sortedWorks = _.sortBy(works, (work) => _.maxBy(work, (w) => w.start));

  return (
    <AnimateHeight
      animateOpacity
      duration={500}
      height={tab === 'Work' ? 'auto' : '0%'}
    >
      <FadeIn>
        {sortedWorks.map((work, i) => {
          let isSelected = selection === i;
          let isVisible = work.some((w) =>
            w.stack.some((s) => !filter || filter === s)
          );
          return (
            <Pane key={i}>
              <AnimateHeight
                duration={500}
                height={isVisible ? 'auto' : '0%'}
                animateOpacity
              >
                <Work index={i} isSelected={isSelected} work={work} />
              </AnimateHeight>
            </Pane>
          );
        })}
      </FadeIn>
    </AnimateHeight>
  );
};

const ProjectListings = () => {
  const { filter, tab, selection } = useContext(SortingContext);
  return (
    <AnimateHeight
      animateOpacity
      duration={500}
      height={tab === 'Projects' ? 'auto' : '0%'}
    >
      <FadeIn>
        {projects.map((project, i) => {
          let isSelected = selection === i;
          let isVisible = project.stack.some(
            (s) => !filter || filter?.includes(s)
          );

          return (
            <Pane key={i}>
              <AnimateHeight
                duration={500}
                height={isVisible ? 'auto' : '0%'}
                animateOpacity
              >
                <Project index={i} isSelected={isSelected} project={project} />
              </AnimateHeight>
            </Pane>
          );
        })}
      </FadeIn>
    </AnimateHeight>
  );
};

export default ListingContainer;
