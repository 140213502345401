import { Pane } from 'evergreen-ui';
import { Acronym } from '../components/Typography';

const LeftPane = () => {
  return (
    <Pane
      borderRight='1px solid #cccccc22'
      paddingTop='2rem'
      paddingLeft='1rem'
      paddingRight='1rem'
      position='sticky'
      top='0'
      display='flex'
      flexDirection='column'
    >
      <Pane height='100px'>
        <Acronym>yjmrobert.</Acronym>
      </Pane>
    </Pane>
  );
};

export default LeftPane;
