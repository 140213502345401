import styled from 'styled-components';
import { Text } from './Typography';

export const Tab = styled(Text)`
  text-transform: uppercase;
  font-family: 'Share Tech Mono', monospace;
  color: ${(props) => (props.active ? '#fff' : 'default')};
  &:after {
    display: block;
    content: '';
    border-bottom: solid 2px #fff;
    transform: ${(props) => (props.active ? 'scaleX(1)' : 'scaleX(0)')};
    transition: transform 200ms ease-in;
    transform-origin: 0% 50%;
  }
  &:hover {
    border-color: #fff;
    color: #fff;
    cursor: pointer;
  }
  &:hover:after {
    transform: scaleX(1);
  }
`;
