import React from 'react';
import { Pane } from 'evergreen-ui';
import { useState } from 'react';
import './App.css';
import LeftPane from './layout/LeftPane';
import MiddlePane from './layout/MiddlePane';
import RightPane from './layout/RightPane';
import { useMediaQuery } from 'react-responsive';

export const SortingContext = React.createContext();

const App = () => {
  const [filter, setFilter] = useState();
  const [tab, setTab] = useState();
  const [selection, setSelection] = useState();

  const isDesktop = useMediaQuery({ query: '(min-width: 1440px' });
  const isLaptop = useMediaQuery({ query: '(min-width: 1024px' });

  return (
    <SortingContext.Provider
      value={{ filter, setFilter, tab, setTab, selection, setSelection }}
    >
      <div className='App'>
        <Pane height='100vh' width='100vw' className='bg-pane'>
          <Pane
            display='grid'
            gridTemplateColumns={
              isDesktop ? '1fr 2fr 3fr' : isLaptop ? '1fr 2fr' : 'auto'
            }
            height='100%'
            overflow='auto'
          >
            {isDesktop && <LeftPane />}
            <MiddlePane />
            <RightPane />
          </Pane>
        </Pane>
      </div>
    </SortingContext.Provider>
  );
};

export default App;
