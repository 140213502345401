import { Pane } from 'evergreen-ui';
import { Acronym } from './Typography';
import styled from 'styled-components';

import { GrMail } from 'react-icons/gr';
import {
  AiFillTwitterCircle,
  AiFillGithub,
  AiFillLinkedin,
} from 'react-icons/ai';
import useHover from '../hooks/Hover';
import { useState, useEffect } from 'react';

const Text = styled(Acronym)`
  display: inline;
  font-size: 20px;
  line-height: 20px;
  transition: all 0.2s ease-in-out;
  color: ${(props) => (props.color ? props.color : '#ffffff3d')};
`;

const IconGraphic = styled.div`
  position: absolute;
  left: ${(props) =>
    props.position?.left ? `${props.position.left}px` : '100px'};
  top: ${(props) =>
    props.position?.top ? `${props.position.top}px` : '100px'};
  font-size: 20px;
  transition: all 0.2s ease-in-out;

  &::before {
    content: '';
    transition: all 0.2s ease-in-out;
    width: ${(props) =>
      props.after?.width ? `${props.before.width}px` : '100px'};
    height: ${(props) =>
      props.after?.height ? `${props.before.height}px` : '100px'};
    border-top: ${(props) =>
      props.position?.inverted
        ? 'none'
        : `1px solid ${props.borderColor ? props.borderColor : '#ffffff1d'}`};
    border-bottom: ${(props) =>
      !props.position?.inverted
        ? 'none'
        : `1px solid ${props.borderColor ? props.borderColor : '#ffffff1d'}`};
    border-left: 1px solid
      ${(props) => (props.borderColor ? props.borderColor : '#ffffff1d')};
    top: ${(props) => (props.before?.top ? `${props.before.top}px` : '100px')};
    left: ${(props) =>
      props.after?.left ? `${props.before.left}px` : '100px'};
    position: absolute;
  }

  &::after {
    content: '';
    transition: all 0.2s ease-in-out;
    width: ${(props) =>
      props.after?.width ? `${props.after.width}px` : '100px'};
    height: ${(props) =>
      props.after?.height ? `${props.after.height}px` : '100px'};
    border-top: ${(props) =>
      props.position?.inverted
        ? 'none'
        : `1px solid ${props.borderColor ? props.borderColor : '#ffffff1d'}`};
    border-bottom: ${(props) =>
      !props.position?.inverted
        ? 'none'
        : `1px solid ${props.borderColor ? props.borderColor : '#ffffff1d'}`};
    border-right: 1px solid
      ${(props) => (props.borderColor ? props.borderColor : '#ffffff1d')};
    top: ${(props) => (props.after?.top ? `${props.after.top}px` : '100px')};
    left: ${(props) => (props.after?.left ? `${props.after.left}px` : '100px')};
    position: absolute;
  }

  svg {
    transition: all 0.2s ease-in-out;
    color: ${(props) => (props.color ? props.color : '#ffffff55')};
    cursor: pointer;
  }
`;

const ContactGraph = () => {
  const [letterHovers, setLetterHovers] = useState({});

  return (
    <Pane
      position='relative'
      width='280px'
      display='flex'
      justify-content='center'
    >
      <Icon setLetterHovers={setLetterHovers} iconName='mail' />
      <Icon setLetterHovers={setLetterHovers} iconName='twitter' />
      <Icon setLetterHovers={setLetterHovers} iconName='ghli' />
      {/* <Icon setLetterHovers={setLetterHovers} iconName='linkedin' /> */}

      <Pane display='inline-block'>
        <Text color={letterHovers['@'] ? '#fff' : null}>@&nbsp;</Text>
        <Text color={letterHovers['yjmrobert'] ? '#fff' : null}>yjmrobert</Text>
        <Text color={letterHovers['@gmail.com'] ? '#fff' : null}>
          &nbsp;@gmail.com
        </Text>
      </Pane>
    </Pane>
  );
};

const iconPositionMap = {
  mail: {
    left: 137,
    top: -28,
  },
  twitter: {
    left: 48,
    top: -45,
  },
  ghli: {
    left: 39,
    top: 28,
    inverted: true,
  },
};

const linemap = {
  mail: {
    after: {
      width: 73,
      height: 20,
      top: 10,
      left: 23,
    },
    before: {
      width: 112,
      height: 20,
      top: 10,
      left: -116,
    },
  },
  twitter: {
    after: {
      width: 45,
      height: 37,
      top: 10,
      left: 23,
    },
    before: {
      width: 45,
      height: 37,
      top: 10,
      left: -48,
    },
  },
  ghli: {
    after: {
      width: 26,
      height: 16,
      top: -6,
      left: 51,
    },
    before: {
      width: 16,
      height: 12,
      top: -2,
      left: -18,
    },
  },
};

function getLetterHovers(iconName, isHovered) {
  switch (iconName) {
    case 'mail':
      return {
        yjmrobert: isHovered,
        '@gmail.com': isHovered,
      };
    case 'twitter':
      return {
        '@': isHovered,
        yjmrobert: isHovered,
      };
    case 'ghli':
      return {
        yjmrobert: isHovered,
      };
    default:
      return {};
  }
}

const Icon = ({ setLetterHovers, iconName }) => {
  const [hoverRef, isHovered] = useHover();

  useEffect(() => {
    setLetterHovers(getLetterHovers(iconName, isHovered));
  }, [isHovered, iconName, setLetterHovers]);

  return (
    <IconGraphic
      position={iconPositionMap[iconName]}
      after={linemap[iconName]?.after}
      before={linemap[iconName]?.before}
      borderColor={isHovered ? '#fff' : null}
    >
      <span ref={hoverRef}>
        {{
          mail: (
            <a href='mailto:yjmrobert@gmail.com'>
              <GrMail color={isHovered ? '#fff' : null} />
            </a>
          ),
          twitter: (
            <a href='https://twitter.com/yjmrobert'>
              <AiFillTwitterCircle color={isHovered ? '#fff' : null} />
            </a>
          ),
          ghli: <GithubLinkedin isHovered={isHovered} />,
        }[iconName] || null}
      </span>
    </IconGraphic>
  );
};

const Spacer = styled.div`
  display: inline;
  height: 1px;
  background-color: ${(props) => (props.color ? props.color : '#ffffff1d')};
  width: 10px;
  transition: all 0.2s ease-in-out;
  margin-bottom: 4px;
  margin-left: 1px;
`;
const GithubLinkedin = ({ isHovered }) => {
  const [ghHoverRef, ghIsHovered] = useHover();
  const [liHoverRef, liIsHovered] = useHover();
  return (
    <Pane display='flex' alignItems='center'>
      <a ref={ghHoverRef} href='https://github.com/yjmrobert'>
        <AiFillGithub color={ghIsHovered ? '#fff' : null} />
      </a>
      <Spacer color={isHovered ? '#fff' : null} />
      <a ref={liHoverRef} href='https://www.linkedin.com/in/yjmrobert/'>
        <AiFillLinkedin color={liIsHovered ? '#fff' : null} />
      </a>
    </Pane>
  );
};

export default ContactGraph;
