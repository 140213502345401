import { CaretRightIcon, Pane } from 'evergreen-ui';
import { useContext } from 'react';
import { Text, ListingTitle, ListingWrapper, Role, Time } from './Typography';
import AnimateHeight from 'react-animate-height';
import { SortingContext } from '../App';
import TechStack from './TechStack';

const Project = ({ isSelected, project, index }) => {
  const { setSelection } = useContext(SortingContext);

  const { role, highlights, company, start, end, stack } = project;
  let startDate = new Date(start);
  let startText = `${startDate.toLocaleString('default', {
    month: 'long',
  })} ${startDate.getFullYear()}`;

  let endText = 'Present';
  if (end !== 'Present') {
    let endDate = new Date(end);
    endText = `${endDate.toLocaleString('default', {
      month: 'long',
    })} ${endDate.getFullYear()}`;
  }

  return (
    <ListingWrapper onClick={() => setSelection(index)} selected={isSelected}>
      <ListingTitle selected={isSelected}>{project.name}</ListingTitle>
      <AnimateHeight duration={500} height={isSelected ? 'auto' : '0%'}>
        <Pane paddingLeft='2.5rem' paddingRight='2.5rem'>
          <Pane display='flex' alignItems='baseline'>
            <Role>{role}</Role>
            <Text>@ {company}</Text>
          </Pane>
          <Time>{`${startText} - ${endText}`}</Time>
          <Pane
            paddingTop='1rem'
            paddingBottom='1rem'
            display='flex'
            flexDirection='column'
          >
            {highlights.map((highlight, i) => (
              <Pane key={i} display='grid' gridTemplateColumns='24px auto'>
                <CaretRightIcon color='#ffffff3d' marginTop='5px' />
                <Text>{highlight}</Text>
              </Pane>
            ))}
          </Pane>
          <TechStack stack={stack} />
        </Pane>
      </AnimateHeight>
    </ListingWrapper>
  );
};

export default Project;
