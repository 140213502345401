import _ from 'lodash';

const allProjects = [
  {
    name: 'Ministry of Transport Ontario',
    client: 'Tidal Migrations',
    role: 'Fullstack Developer',
    company: 'Apption',
    start: '2020-06-01',
    end: 'Present',
    stack: [
      'ReactJS',
      'GraphQL',
      'Spring Boot',
      'Azure',
      'CI/CD',
      'SQL',
      'Java',
      'Less',
      'CSS',
    ],
    highlights: [
      'Full stack development and refactor of existing application to migrate to Azure Cloud',
      'Spring Boot back-end serving a GraphQL API, implemented RBAC with Auth0 and Spring Security for both internal and external users',
      "ReactJS SPA front-end with custom themed AntDesign components, secured with Auth0's SPA framework",
    ],
  },
  {
    name: 'COVID Return To Play',
    client: 'Canada Soccer',
    role: 'Fullstack Developer',
    company: 'Apption',
    start: '2020-05-01',
    end: '2020-06-01',
    stack: ['Blazor', '.NET', 'C#', 'Azure', 'CI/CD', 'SQL', 'SASS', 'CSS'],
    highlights: [
      'Full stack development of cloud based application to collect and authorize soccer organizations to return to play',
      'Server-side Blazor in .NET Core with server-side pre-rendering and EF Core migrations',
      'Streamlined CI/CD through Azure DevOps in standard Dev/Stg/Prod cycles for ease of deployment',
    ],
  },
  {
    name: 'National Soccer Registry',
    client: 'Canada Soccer',
    role: 'Fullstack Developer',
    company: 'Apption',
    start: '2020-01-01',
    end: '2020-05-01',
    stack: [
      'ReactJS',
      '.NET',
      'C#',
      'Azure',
      'CI/CD',
      'SQL',
      'Serverless',
      'NUnit',
      'SASS',
      'CSS',
    ],
    highlights: [
      "Full stack development of national soccer registry to integrate with FIFA's International Service Bus",
      'ASP.NET Core, Azure Function (serverless), and ReactJS SPA with MaterialUI',
      'Development of extensive internal NUnit testing suite to validate SDK updates from FIFA',
    ],
  },
  {
    name: 'Datahunter',
    client: 'Apption',
    role: 'Fullstack Developer',
    company: 'Apption',
    start: '2018-09-01',
    end: '2019-12-01',
    stack: ['Blazor', '.NET', 'C#', 'CI/CD', 'SQL', 'SASS', 'CSS'],
    highlights: [
      'Full stack development of Datahunter application to analyze and display ML results on from any data source',
      'Developed open source Blazor component library to re-use across other projects',
    ],
  },
  {
    name: 'Cybersecurity Vendor Portal',
    client: 'Shared Services Canada',
    role: 'Fullstack Developer',
    company: 'Apption',
    start: '2019-02-01',
    end: '2019-05-01',
    stack: [
      'Blazor',
      '.NET',
      'C#',
      'CI/CD',
      'SQL',
      'Azure',
      'Azure AD',
      'NUnit',
      'SASS',
      'CSS',
    ],
    highlights: [
      'Full stack development of cloud application to collect and organization multiple internal workflows',
      'Blazor server-side with Azure AD authentication and authorization',
      'Implemented custom metadata tagging to create flexible user-defined groupings with global searching',
    ],
  },
  {
    name: 'Non-Generic Agreements',
    client: 'Canada Post',
    role: 'Frontend Developer',
    company: 'Apption',
    start: '2017-01-01',
    end: '2018-08-01',
    stack: ['ReactJS', '.NET', 'SharePoint', 'C#', 'NUnit', 'CSS'],
    highlights: [
      'Deployed ReactJS SPA inside SharePoint page to bypass engineering constraints',
      'Developed optimized workflow patterns with drag-and-drop, check-in and check-out version control, and metatag classification',
      'Ran asynchronous REST calls with SharePoint to allow seamless user experience',
    ],
  },
  {
    name: 'ArcGIS',
    client: 'Government of Canada',
    company: 'Government of Canada',
    role: 'Fullstack Developer',
    start: '2015-05-01',
    end: '2015-12-01',
    stack: [
      'Spring Boot',
      'REST',
      'ArcGIS',
      'JavaScript',
      'jQuery',
      'Java',
      'HTML',
      'CSS',
    ],
    highlights: [
      'Reconstructed in Java the Haversine formula relating the sides and angles of spherical triangles to work in in parallel with Vincenty’s formulae for the inverse and Direct geodesic problems',
      'Used the above computations to develop a Spring Boot application to conceptualize, analyze, and predict NMEA, GeoJSON, and KML annotated geographic paths',
      'Assembled and documented API of polymorphic units and functions to manipulate and represent geographic data in ArcGIS',
    ],
  },
  {
    name: 'LHIN Forum',
    client: 'Champlain LHIN',
    company: 'Independent',
    role: 'Fullstack Developer',
    start: '2016-05-01',
    end: '2016-08-01',
    stack: ['Drupal', 'cPanel', 'SQL', 'JavaScript', 'PHP', 'CSS'],
    highlights: [
      "Deployed online event listing, blog, and forum for healthcare professionals' Continuing Education mandate",
      'Integrated future event schedule with Google Maps to pin-point exact location of learning convention',
      'Applied standard RBAC permissions for forum moderators and field-specific discussions based on authenticated users',
    ],
  },
];

export const projects = _.orderBy(allProjects, ['start'], ['desc']);

export const techs = [
  'ReactJS',
  'Blazor',
  'GraphQL',
  'Spring Boot',
  '.NET',
  'SQL',
  'Azure',
  'CI/CD',
];
